const p_modifyObject = ({ actionNode, contextData, appController, actionNodeLogger, actionNodeRunner }) =>
	new Promise((resolve, reject) => {
		const dataSource = appController.getDataSource(actionNode.dataSourceId)
		if (!dataSource) return reject(new Error('Unable to find DataSource'))

		const rootActionId = actionNodeRunner.getRootAction().id

		contextData = appController.refreshContextData(contextData, {
			actionNodeId: actionNode.id,
			actionId: rootActionId,
		})

		dataSource
			.p_modifyMultipleObjects({
				actionId: rootActionId,
				actionNodeId: actionNode.id,
				contextData,
				propertyValues: actionNode.defaultValues,
				dataSourceId: actionNode.dataSourceId,
				selectionType: actionNode.selectionType,
				staticFilter: actionNode.staticFilter,
				filterDescriptor: actionNode.filterDescriptor,
				logger: actionNodeLogger,
			})
			.then(resolve)
			.catch(reject)
	})

export default p_modifyObject
